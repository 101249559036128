<template>
  <div class="answer">
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
    </div>
    <div class="width_1200">
      <practice-Record-details></practice-Record-details>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadcrumb.vue";
import practiceRecordDetails from "@/components/practiceRecordDetails.vue";
export default {
  data() {
    return {
      answer: {},
      radio: "",
      list: [],
      obj: [],
      number: 1,
      mobile:false,
      dialogVisible: false,
      width: "",
      correctionArr:{}
    };
  },
  components: {
    BreadCrumb,
    practiceRecordDetails
  },
  created() {
  },
  mounted() {},
  methods: {
  },
};
</script>

<style lang="less" scoped>
.answer {
  // margin-top: -30px;
  background-color: #f5f5f5;
  font-size: 14px;
}
</style>