/* * @Author: liuzhixiang * @Date: 2021-02-06 15:17:54 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-03-03 19:49:05 */
<template>
  <div class="testPaper" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="练习详情"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div class="cont">
      <div class="top">
        <div class="title">{{ list.Name }}</div>
        <!-- <p class="intr">
            本卷共分为2大题8小题，作答时间为120分钟，总分100分；
          </p>-->
      </div>
      <div class="list" v-if="list.length > 0">
        <div class="li" v-for="(li, i) in list" :key="i">
          <div class="paperBank">
            <div class="questionTitle">
              <p class="tmtype">
                <span
                  :class="
                    li.QuestionCategory == 0
                      ? 'bgc_3A78F9'
                      : li.QuestionCategory == 1
                      ? 'bgc_FBB03B'
                      : li.QuestionCategory == 2
                      ? 'bgc_00AE62'
                      : li.QuestionCategory == 3
                      ? 'bgc_B938B5'
                      : li.QuestionCategory == 4
                      ? 'bgc_9682EC'
                      : 'bgc_37E6CF'
                  "
                >
                  {{
                    li.QuestionCategory == 0
                      ? "单选题"
                      : li.QuestionCategory == 1
                      ? "多选题"
                      : li.QuestionCategory == 2
                      ? "判断题"
                      : li.QuestionCategory == 3
                      ? "填空题"
                      : li.QuestionCategory == 4
                      ? "简答题"
                      : "论述题"
                  }}
                </span>
                <span
                  v-if="
                    li.QuestionCategory == 0 ||
                    li.QuestionCategory == 1 ||
                    li.QuestionCategory == 2 ||
                    li.QuestionCategory == 3
                  "
                  :class="li.IsCorrect != true ? 'details_sign' : ''"
                  >题目{{ i + 1 }}：{{ li.QuestionTitle }}</span
                >
                <span v-else>题目{{ i + 1 }}：{{ li.QuestionTitle }}</span>
              </p>
            </div>
            <el-radio-group
              v-if="li.QuestionCategory === 0"
              v-model="li.StudentAnswerStr"
              disabled
            >
              <p v-for="(lbb, p) in li.BankItem" :key="p">
                <el-radio :label="lbb.ItemNum"
                  >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                >
              </p>
              <el-button
                size="mini"
                v-if="li.parsingShow == false"
                @click="parsClick(li)"
                >解析</el-button
              >
              <div v-if="li.parsingShow == true" class="js">
                <div>
                  【答案】: {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                </div>
                <div>
                  【解析】:
                  <span
                    v-html="
                      li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                    "
                  ></span>
                </div>
                <div>【点拨】: {{ li.Dial == null ? "无" : li.Dial }}</div>
              </div>
            </el-radio-group>
            <el-checkbox-group
              v-else-if="li.QuestionCategory === 1"
              v-model="li.StudentAnswerStr"
              disabled
            >
              <p v-for="(lbb, p) in li.BankItem" :key="p">
                <el-checkbox :label="lbb.ItemNum"
                  >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-checkbox
                >
              </p>
              <el-button
                size="mini"
                v-if="li.parsingShow == false"
                @click="parsClick(li)"
                >解析</el-button
              >
              <div v-if="li.parsingShow == true" class="js">
                <div>
                  【答案】: {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                </div>
                <div>
                  【解析】:
                  <span
                    v-html="
                      li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                    "
                  ></span>
                </div>
                <div>【点拨】: {{ li.Dial == null ? "无" : li.Dial }}</div>
              </div>
            </el-checkbox-group>
            <el-radio-group
              v-else-if="li.QuestionCategory === 2"
              v-model="li.StudentAnswerStr"
              disabled
            >
              <p v-for="(lbb, p) in li.BankItem" :key="p">
                <el-radio :label="lbb.ItemNum"
                  >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                >
              </p>
              <el-button
                size="mini"
                v-if="li.parsingShow == false"
                @click="parsClick(li)"
                >解析</el-button
              >
              <div v-if="li.parsingShow == true" class="js">
                <div>
                  【答案】: {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                </div>
                <div>
                  【解析】:
                  <span
                    v-html="
                      li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                    "
                  ></span>
                </div>
                <div>【点拨】: {{ li.Dial == null ? "无" : li.Dial }}</div>
              </div>
            </el-radio-group>
            <div v-else-if="li.QuestionCategory === 3">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入填空,多个填空以中文；隔开"
                v-model="li.StudentAnswerStr"
                disabled
              ></el-input>
              <el-button
                size="mini"
                v-if="li.parsingShow == false"
                @click="parsClick(li)"
                >解析</el-button
              >
              <div v-if="li.parsingShow == true" class="js">
                <div>
                  【答案】: {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                </div>
                <div>
                  【解析】:
                  <span
                    v-html="
                      li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                    "
                  ></span>
                </div>
                <div>【点拨】: {{ li.Dial == null ? "无" : li.Dial }}</div>
              </div>
            </div>
            <div v-else-if="li.QuestionCategory === 4">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入简答"
                v-model="li.StudentAnswerStr"
                disabled
              ></el-input>
              <el-button
                size="mini"
                v-if="li.parsingShow == false"
                @click="parsClick(li)"
                >解析</el-button
              >
              <div v-if="li.parsingShow == true" class="js">
                <div>
                  【答案】: {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                </div>
                <div>
                  【解析】:
                  <span
                    v-html="
                      li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                    "
                  ></span>
                </div>
                <div>【点拨】: {{ li.Dial == null ? "无" : li.Dial }}</div>
              </div>
            </div>
            <div v-else-if="li.QuestionCategory === 5">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入论述"
                v-model="li.StudentAnswerStr"
                disabled
              ></el-input>
              <el-button
                size="mini"
                v-if="li.parsingShow == false"
                @click="parsClick(li)"
                >解析</el-button
              >
              <div v-if="li.parsingShow == true" class="js">
                <div>
                  【答案】: {{ li.AnswerStr == null ? "无" : li.AnswerStr }}
                </div>
                <div>
                  【解析】:
                  <span
                    v-html="
                      li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                    "
                  ></span>
                </div>
                <div>【点拨】: {{ li.Dial == null ? "无" : li.Dial }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list" v-else>
        <div class="li">
          <div class="paperBank">
            <no-data></no-data>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { questionBankQueryChapterRecordDetail } from "@/api/questionBank";
import noData from "@/components/noData";
export default {
  data() {
    return {
      answer: {},
      list: [],
      obj: [],
      checkList: [],
      textarea: [],
      user: {},
      route: {},
      mobile: false,
      width: "",
      correctionArr: {},
      isWxApplets: true,
    };
  },
  components: {
    noData,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "练习详情";
      this.isWxApplets = false;
    }
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.route = this.$route.query;
    let sUserAgent = navigator.userAgent.toLowerCase();
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        sUserAgent
      )
    ) {
      //跳转移动端页面
      this.mobile = true;
    } else {
      //跳转pc端页面
      this.mobile = false;
    }
    this.init();
  },
  mounted() {},
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    //解析
    parsClick(obj) {
      obj.parsingShow = true;
      // console.log(obj, "");
    },
    //题目列表
    async init() {
      this.answer = this.$route.query;
      const res = await questionBankQueryChapterRecordDetail(
        this.answer.DataId
      );
      if (res.success === true) {
        this.list = res.response;
        this.list.forEach((item) => {
          if (item.QuestionCategory == 1) {
            item.AnswerStr = item.AnswerStr.split("");
            item.StudentAnswerStr = item.StudentAnswerStr.split(",");
          }
          this.$set(item, "parsingShow", false);
        });
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.testPaper {
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .cont {
    .top {
      background-color: #fff;
      margin-bottom: 10px;
      .title {
        line-height: 100%;
        padding: 20px 0;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
      .intr {
        padding: 0 10px 10px;
      }
    }
    .list {
      margin-bottom: 15px;
      .li {
        .js {
          font-size: 14px;
          line-height: 175%;
        }
        .title {
          padding: 10px 15px;
          background-color: #fff;
          margin-bottom: 10px;
          font-size: 15px;
        }
        .paperBank {
          margin-bottom: 5px;
          padding: 20px 30px;
          background-color: #fff;
          .questionTitle {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            .correction {
              margin-left: 5px;
              color: #c5c5c5;
              font-size: 12px;
              width: 40px;
              cursor: pointer;
            }
            p {
              line-height: 22px;
              // height: 22px;
            }
            .tmtype {
              flex: 1;
              span {
                &:first-child {
                  display: inline-block;
                  background-color: #56b155;
                  line-height: 22px;
                  height: 22px;
                  color: #fff;
                  padding: 0 8px;
                  border-radius: 20px;
                  margin-right: 10px;
                }
              }
            }
            .tmmc {
              margin-top: 10px;
            }
          }
          .el-radio-group,
          .el-checkbox-group {
            .el-checkbox {
              // display: flex;
              // align-items: center;
            }
            .el-radio,
            .el-radio__input,
            .el-checkbox,
            .el-checkbox__input {
              white-space: normal !important;
              line-height: 175%;
            }
            p {
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .el-textarea {
            margin-bottom: 10px;
          }
          .js {
            line-height: 175%;
            font-size: 12px;
          }
        }
      }
    }
    .immediately {
      background-color: #fff;
      text-align: center;
      padding: 10px 0;
      margin-bottom: 20px;
    }
  }
}
.details_sign {
  color: #f61717 !important;
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
