var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"testPaper",style:({ marginTop: _vm.isWxApplets ? '46px' : '' })},[(_vm.isWxApplets)?[_c('van-nav-bar',{attrs:{"title":"练习详情","fixed":"","left-arrow":""},on:{"click-left":_vm.onClickLeft}})]:_vm._e(),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.list.Name))])]),(_vm.list.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.list),function(li,i){return _c('div',{key:i,staticClass:"li"},[_c('div',{staticClass:"paperBank"},[_c('div',{staticClass:"questionTitle"},[_c('p',{staticClass:"tmtype"},[_c('span',{class:li.QuestionCategory == 0
                    ? 'bgc_3A78F9'
                    : li.QuestionCategory == 1
                    ? 'bgc_FBB03B'
                    : li.QuestionCategory == 2
                    ? 'bgc_00AE62'
                    : li.QuestionCategory == 3
                    ? 'bgc_B938B5'
                    : li.QuestionCategory == 4
                    ? 'bgc_9682EC'
                    : 'bgc_37E6CF'},[_vm._v(" "+_vm._s(li.QuestionCategory == 0 ? "单选题" : li.QuestionCategory == 1 ? "多选题" : li.QuestionCategory == 2 ? "判断题" : li.QuestionCategory == 3 ? "填空题" : li.QuestionCategory == 4 ? "简答题" : "论述题")+" ")]),(
                  li.QuestionCategory == 0 ||
                  li.QuestionCategory == 1 ||
                  li.QuestionCategory == 2 ||
                  li.QuestionCategory == 3
                )?_c('span',{class:li.IsCorrect != true ? 'details_sign' : ''},[_vm._v("题目"+_vm._s(i + 1)+"："+_vm._s(li.QuestionTitle))]):_c('span',[_vm._v("题目"+_vm._s(i + 1)+"："+_vm._s(li.QuestionTitle))])])]),(li.QuestionCategory === 0)?_c('el-radio-group',{attrs:{"disabled":""},model:{value:(li.StudentAnswerStr),callback:function ($$v) {_vm.$set(li, "StudentAnswerStr", $$v)},expression:"li.StudentAnswerStr"}},[_vm._l((li.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-radio',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(li.parsingShow == false)?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.parsClick(li)}}},[_vm._v("解析")]):_vm._e(),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial))])]):_vm._e()],2):(li.QuestionCategory === 1)?_c('el-checkbox-group',{attrs:{"disabled":""},model:{value:(li.StudentAnswerStr),callback:function ($$v) {_vm.$set(li, "StudentAnswerStr", $$v)},expression:"li.StudentAnswerStr"}},[_vm._l((li.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-checkbox',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(li.parsingShow == false)?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.parsClick(li)}}},[_vm._v("解析")]):_vm._e(),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial))])]):_vm._e()],2):(li.QuestionCategory === 2)?_c('el-radio-group',{attrs:{"disabled":""},model:{value:(li.StudentAnswerStr),callback:function ($$v) {_vm.$set(li, "StudentAnswerStr", $$v)},expression:"li.StudentAnswerStr"}},[_vm._l((li.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-radio',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(li.parsingShow == false)?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.parsClick(li)}}},[_vm._v("解析")]):_vm._e(),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial))])]):_vm._e()],2):(li.QuestionCategory === 3)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入填空,多个填空以中文；隔开","disabled":""},model:{value:(li.StudentAnswerStr),callback:function ($$v) {_vm.$set(li, "StudentAnswerStr", $$v)},expression:"li.StudentAnswerStr"}}),(li.parsingShow == false)?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.parsClick(li)}}},[_vm._v("解析")]):_vm._e(),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial))])]):_vm._e()],1):(li.QuestionCategory === 4)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入简答","disabled":""},model:{value:(li.StudentAnswerStr),callback:function ($$v) {_vm.$set(li, "StudentAnswerStr", $$v)},expression:"li.StudentAnswerStr"}}),(li.parsingShow == false)?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.parsClick(li)}}},[_vm._v("解析")]):_vm._e(),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial))])]):_vm._e()],1):(li.QuestionCategory === 5)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入论述","disabled":""},model:{value:(li.StudentAnswerStr),callback:function ($$v) {_vm.$set(li, "StudentAnswerStr", $$v)},expression:"li.StudentAnswerStr"}}),(li.parsingShow == false)?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.parsClick(li)}}},[_vm._v("解析")]):_vm._e(),(li.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(li.AnswerStr == null ? "无" : li.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(li.Dial == null ? "无" : li.Dial))])]):_vm._e()],1):_vm._e()],1)])}),0):_c('div',{staticClass:"list"},[_c('div',{staticClass:"li"},[_c('div',{staticClass:"paperBank"},[_c('no-data')],1)])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }